import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

// import { Plugins } from '@capacitor/core';
// const { Geolocation, Network } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  latitude;
  longitude;

  constructor(
    private platform: Platform,
    private device: Device,
    private geolocation: Geolocation
  ) { 

    this.geolocation.getCurrentPosition().then((resp) => {

      console.log('PlatformService ---- resp:',resp);

      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      console.log('getCurrentPosition res:',resp);
    }).catch((error) => {
       console.log('Error getting location', error);
    });

    console.log('PlatformService ----');

    // Geolocation.getCurrentPosition().then((position) => {

    //   console.log('PlatformService ---- position:',position);
    //   this.latitude = position.coords.latitude;
    //   this.longitude = position.coords.longitude;

    // }, (err) => {
    //   console.log('PlatformService ---- Error getting Location :: ',err);
    // });

  }//constructor()

  isAndroid() {
    return this.platform.is('android');
  }//isAndroid()

  isiOS() {
    return this.platform.is('ios');
  }//isiOS()

  // getPlatformIntCode(){
  //   var android = this.isAndroid();
  //   var ios = this.isiOS();

  //   if(android==true){
  //     return 0;
  //   }
  //   else if(ios==true){
  //     return 1;
  //   }
  //   else{
  //     return 2;
  //   }

  // }//getPlatformIntCode()

  getPlatformIntCode(){
    let platform = this.device.platform;

    if(platform=="Android"){
      return 0;
    }
    else if(platform=="iOS"){
      return 1;
    }
    else{
      return 2;
    }
  }//getPlatformIntCode()
  
  getTimezoneName(){
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getDeviceInfo(){
    if(this.device.version!=null){
      return  this.device.manufacturer + ' - ' + this.device.model + ' - ' + this.device.platform + ' - ' + this.device.version;
      //return this.device.platform + ' - ' + this.device.version;
    }
    else{
      return 'Browser';
    }
  }

  getLatitude(){
    return this.latitude;
  }

  getLongitude(){
    return this.longitude;
  }

}//class PlatformService
