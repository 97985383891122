import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AllService } from '../all.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public allService: AllService,
    private storage: Storage,
    private router: Router,
    public swUpdate: SwUpdate
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if(this.swUpdate.isEnabled){
      this.swUpdate.checkForUpdate().then(() => {
        console.log("i SwUpdate checkForUpdate");
      }).catch((e) =>{
        console.error('i SwUpdate checkForUpdate Error:',e);
      });
    }

    if (this.allService.getAccessToken()) {
      request = this.addToken(request, this.allService.getAccessToken());
    }

    return next.handle(request).pipe(catchError(error => {

      if (error instanceof HttpErrorResponse && error.status === 401) {

        if ( request.url.includes("refreshtoken") ) {
          //this.auth.logout();
          this.isRefreshing = false;
          this.storage.clear();
          this.allService.doLogout();
          this.router.navigate(['/login']);

          var custError = { message : "Unauthorized User" }
          return throwError(custError);
        }else{
          return this.handle401Error(request, next);
        }


        
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.allService.doRefreshToken().pipe(
        switchMap((token: any) => {
          console.log('token:',token);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token['data']['access_token']);
          return next.handle(this.addToken(request, token['data']['access_token']));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}