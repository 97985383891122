import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoggedInService } from './services/logged-in.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'instruction',
    pathMatch: 'full'
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'instruction',
    loadChildren: () => import('./instruction/instruction.module').then( m => m.InstructionPageModule),
    canActivate: [LoggedInService]
  },
  {
    path: 'signupas',
    loadChildren: () => import('./signupas/signupas.module').then( m => m.SignupasPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'forgototp',
    loadChildren: () => import('./forgototp/forgototp.module').then( m => m.ForgototpPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signupotp',
    loadChildren: () => import('./signupotp/signupotp.module').then( m => m.SignupotpPageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'pharmacies',
    loadChildren: () => import('./pharmacies/pharmacies.module').then( m => m.PharmaciesPageModule)
  },
  {
    path: 'pickuplocation',
    loadChildren: () => import('./pickuplocation/pickuplocation.module').then( m => m.PickuplocationPageModule)
  },
  {
    path: 'addnote',
    loadChildren: () => import('./addnote/addnote.module').then( m => m.AddnotePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'user-request',
    loadChildren: () => import('./user-request/user-request.module').then( m => m.UserRequestPageModule)
  },
  {
    path: 'ui-designs',
    loadChildren: () => import('./ui-designs/ui-designs.module').then( m => m.UiDesignsPageModule)
  },
  {
    path: 'job-list',
    loadChildren: () => import('./job-list/job-list.module').then( m => m.JobListPageModule)
  },
  {
    path: 'pickups',
    loadChildren: () => import('./pickups/pickups.module').then( m => m.PickupsPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'rate-trip',
    loadChildren: () => import('./rate-trip/rate-trip.module').then( m => m.RateTripPageModule)
  },
  {
    path: 'ride-complete',
    loadChildren: () => import('./ride-complete/ride-complete.module').then( m => m.RideCompletePageModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('./delivery/delivery.module').then( m => m.DeliveryPageModule)
  },
  {
    path: 'pharmacy-nearby',
    loadChildren: () => import('./pharmacy-nearby/pharmacy-nearby.module').then( m => m.PharmacyNearbyPageModule)
  },
  {
    path: 'news-detail',
    loadChildren: () => import('./news-detail/news-detail.module').then( m => m.NewsDetailPageModule)
  },
  {
    path: 'testpage',
    loadChildren: () => import('./testpage/testpage.module').then( m => m.TestpagePageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'user/pharmacy-list',
    loadChildren: () => import('./user/pharmacy-list/pharmacy-list.module').then( m => m.PharmacyListPageModule)
  },
  {
    path: 'driver/pharmacy-job-list',
    loadChildren: () => import('./driver/pharmacy-job-list/pharmacy-job-list.module').then( m => m.PharmacyJobListPageModule)
  },
  {
    path: 'order-list',
    loadChildren: () => import('./order-list/order-list.module').then( m => m.OrderListPageModule)
  },
  {
    path: 'online-drivers',
    loadChildren: () => import('./online-drivers/online-drivers.module').then( m => m.OnlineDriversPageModule)
  },
  {
    path: 'ac-clone',
    loadChildren: () => import('./ac-clone/ac-clone.module').then( m => m.AcClonePageModule)
  },
  {
    path: 'order-input',
    loadChildren: () => import('./order-input/order-input.module').then( m => m.OrderInputPageModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('./order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'add-card',
    loadChildren: () => import('./add-card/add-card.module').then( m => m.AddCardPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'driver-profile',
    loadChildren: () => import('./driver-profile/driver-profile.module').then( m => m.DriverProfilePageModule)
  },
  {
    path: 'stripe-connect',
    loadChildren: () => import('./stripe-connect/stripe-connect.module').then( m => m.StripeConnectPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'select-state',
    loadChildren: () => import('./select-state/select-state.module').then( m => m.SelectStatePageModule)
  },
  {
    path: 'background-info',
    loadChildren: () => import('./background-info/background-info.module').then( m => m.BackgroundInfoPageModule)
  },
  {
    path: 'signature-pad',
    loadChildren: () => import('./signature-pad/signature-pad.module').then( m => m.SignaturePadPageModule)
  },
  {
    path: 'dropoff-modal',
    loadChildren: () => import('./dropoff-modal/dropoff-modal.module').then( m => m.DropoffModalPageModule)
  },
  {
    path: 'rate-user',
    loadChildren: () => import('./rate-user/rate-user.module').then( m => m.RateUserPageModule)
  },
  {
    path: 'order-info',
    loadChildren: () => import('./order-info/order-info.module').then( m => m.OrderInfoPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
