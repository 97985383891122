import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { NavController } from '@ionic/angular';

import { Platform, MenuController, AlertController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//import { OneSignal, OSNotificationPayload } from '@ionic-native/onesignal/ngx';

import { Storage } from '@ionic/storage';

import { AllService } from './all.service';
import { PlatformService } from './services/platform.service';

import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { Plugins } from '@capacitor/core';
const { Geolocation, Network } = Plugins;

const { SplashScreen } = Plugins;

import { Capacitor } from '@capacitor/core';
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

import { ToastController } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';

import {
	PushNotification,
	PushNotificationToken,
	PushNotificationActionPerformed,
} from '@capacitor/core';

const { PushNotifications } = Plugins;

import { Location } from '@angular/common';

import { EventService }  from './services/event.service';
import { MessagingService } from './services/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
  ];
  public logout = false;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private allService: AllService,
    private router: Router,
    private menuCtrl: MenuController,
    private alertCtrl: AlertController,
    //private oneSignal: OneSignal,
    private diagnostic: Diagnostic,
    private locationAccuracy: LocationAccuracy,
	private navCtrl: NavController,
	private _location: Location,
	private platformService: PlatformService,
	private eventService: EventService,
  public toastController: ToastController,
  public swUpdate: SwUpdate,
  private messagingService: MessagingService,
  private afMessaging: AngularFireMessaging
  ) {
    //this.initializeApp();

    if(this.swUpdate.isEnabled){

      this.swUpdate.checkForUpdate().then(() => {
        console.log("SwUpdate checkForUpdate");
      }).catch((e) =>{
        console.error('SwUpdate checkForUpdate Error:',e);
      });
  
      this.swUpdate.available.subscribe(event => {
          console.log('current version is', event.current);
          console.log('available version is', event.available);
    
    
          this.swUpdate.activateUpdate().then(() => {
            console.log("SwUpdate activateUpdate");
            this.pwaNewVersionAvailable();
          }).catch((e) =>{
            console.error('SwUpdate activateUpdate Error:',e);
          });
      },
      err => {
        console.log("swUpdate available error",err);
      });

      this.swUpdate.activated.subscribe(event => {
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });

    }//swUpdate

    
  }

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      if(!this.platform.is('android') && !this.platform.is('ios')){
        this.setupPwaPushNotification();
        } else {
          console.log("NOT WEB"); 
        }
      
      this.registerBackButton();

      if (isPushNotificationsAvailable) {
        this.setupPushNotification();
      }
      else{
        console.log("Capacitor PushNotification plugin not available");
      }

      

      this.statusBar.styleDefault();

      setTimeout(() => {
        this.statusBar.backgroundColorByHexString('#dfecff');
      }, 1100);

      setTimeout(() => {
        SplashScreen.hide();  
      }, 1000);

      

      //this.oneSignal.startInit('7402d69a-f903-4279-8a3e-3be68f21e2bb', '614456259150');

      //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

      // this.oneSignal.handleNotificationReceived().subscribe(() => {
      //   // do something when notification is received
      //   alert("Notification Received!");
      // });

      // this.oneSignal.handleNotificationOpened().subscribe(() => {
      //   // do something when a notification is opened
      //   alert("Notification Opened!");
      // });

      //this.oneSignal.endInit();

      

      
      this.checkLocationStatus();
      //this.requestForEnableLocation();
      this.watchNetworkStatus();

      //this.splashScreen.hide();

      
      
    });//this.platform.ready()

    this.allService.loginStatus$.subscribe(data => {
      //console.log('data',data);
      if (data.event === 'login') {
        this.logout = true;
        this.menuPages(true,data.user);
      }
      else {
        this.logout = false;
        this.menuPages(false,"");
      }
    });
    
  }//initializeApp()

  pageNavigation(){
    this.storage.get('user').then(
      userInfo => {
        if(userInfo){
          this.allService.setUserType(userInfo['requested_user_type'],userInfo['user_type']);

          this.logout = true;
          this.menuPages(true,userInfo);
          
          //this.navCtrl.navigateRoot('/landing');

          //this.router.navigate(['/landing']);
          //this.router.navigate(['rate-user']);
        }
        else {
          this.logout = false;
          this.menuPages(false,userInfo);
          
          //this.navCtrl.navigateRoot('/instruction');
          
          //this.router.navigate(['/instruction']);
          //this.router.navigate(['/forgototp']);
        }
      }
    );
  }//pageNavigation()

  checkLocationStatus(){
    //console.log('------- checkLocationStatus -------');
    this.diagnostic.isLocationEnabled()
    .then((state) => {
      //console.log('state:',state);
      //alert('state:'+JSON.stringify(state));
      if (state == false){
        this.requestForEnableLocation()
      }//if
    }).catch((e) =>{
      console.error('checkLocationStatus isLocationEnabled Error:',e);
    });
  }//checkLocationStatus()

  requestForEnableLocation(){
    //console.log('------- requestForEnableLocation -------');
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {

      //console.log('canRequest------',canRequest);

      //alert('canRequest:'+JSON.stringify(canRequest));

      if(canRequest) {
        // the accuracy option will be ignored by iOS
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => console.log('Request successful-------'),
          (error) => {
            console.log('Error requesting location permissions-------', error);
            this.requestForEnableLocation();
          }
        );
      }
      else{
        //alert('canRequest:else');
      }
    
    });

    //{message: "User chose not to make required location settings changes.", code: 4}

  }//requestForEnableLocation()


  getNetworkStatus(){
    Network.getStatus().then((status) => {
      //console.log('getNetworkStatus status:',status);
      if (!status.connected) {
        this.presentNetworkAlert();

        let device_type = this.platformService.getPlatformIntCode();
        if(device_type!=2){
          this.router.navigate(['/login']);
        }
        else
        {
          this.pageNavigation();
        }
      }
      else{
        this.pageNavigation();
      }
    }, (err) => {
      console.error('getNetworkStatus err:',err);
    });
  }//getNetworkStatus()


  watchNetworkStatus(){
    this.getNetworkStatus();

     Network.addListener('networkStatusChange', (status) => {
      //console.log('watchNetworkStatus status:',status);
      if (!status.connected) {
        this.presentNetworkAlert();
      } else {
          // let device_type = this.platformService.getPlatformIntCode();
          // if(device_type==2){
          //   this.toastController.dismiss();
          // }
          // else{
          //   this.alertCtrl.dismiss();
          // }      
          this.alertCtrl.dismiss();
      }
    });

  }//watchNetworkStatus()

  async presentNetworkAlert() {

    // let device_type = this.platformService.getPlatformIntCode();

    // if(device_type==2){

    //   const toast = await this.toastController.create({
    //     message: 'You are offline',
    //     position: 'top',
    //     cssClass: 'offlineToast'
    //     //duration: 2000
    //   });
    //   toast.present();

    // }
    // else{

    //   const alert = await this.alertCtrl.create({
    //     //cssClass: 'my-custom-class',
    //     header: 'No Internet',
    //     subHeader: 'Please check your network connection',
    //     backdropDismiss: false
    //     //message: 'This is an alert message.',
    //     //buttons: ['OK']
    //   });
  
    //   await alert.present();

    // }

    const alert = await this.alertCtrl.create({
      //cssClass: 'my-custom-class',
      header: 'No Internet',
      subHeader: 'Please check your network connection',
      backdropDismiss: false
      //message: 'This is an alert message.',
      //buttons: ['OK']
    });

    await alert.present();

  }//presentNetworkAlert()

  menuPages(isLoggedIn,user){
    if(isLoggedIn){
      //Logged In

      //User Type : User
      if(user.user_type==1 || user.user_type==""){

        this.appPages = [
          {
            title: 'Home',
            url: '/landing',
            icon: 'home-outline'
          },
          {
            title: 'Contact Us',
            url: '/contactus',
            icon: 'mail-unread-outline'
          },
          {
            title: 'About',
            url: '/aboutus',
            icon: 'document-outline'
          },
          {
            title: 'Change Password',
            url: '/changepassword',
            icon: 'lock-open-outline'
          },
        ];

      }//User

      //User Type : Driver
      if(user.user_type==3){

        this.appPages = [
          {
            title: 'Home',
            url: '/landing',
            icon: 'home-outline'
          },
          // {
          //   title: 'Pickup Request',
          //   url: '/user-request',
          //   icon: 'barcode-outline'
          // },
          // {
          //   title: 'Job List',
          //   url: '/job-list',
          //   icon: 'barcode-outline'
          // },
          {
            title: 'Contact Us',
            url: '/contactus',
            icon: 'mail-unread-outline'
          },
          {
            title: 'About',
            url: '/aboutus',
            icon: 'document-outline'
          },
          {
            title: 'Change Password',
            url: '/changepassword',
            icon: 'lock-open-outline'
          },
        ];

      }//Driver


      
    } 
    else{
      //Not Logged In

      this.appPages = [
        {
          title: 'Instructions',
          url: 'instruction',
          icon: 'enter'
        },
        {
          title: 'Log in / Sign up',
          url: 'onboarding',
          icon: 'home'
        },
      ];
      
    }
  }//menuPages

  async doLogout() {
    this.menuCtrl.toggle();
    const alert = await this.alertCtrl.create({
      header: 'Logout',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Logout',
          handler: () => {
            this.logoutAPICall();

            this.logout = false;
            this.allService.doLogout();

            setTimeout(() => {
              this.storage.clear();
            }, 1000);

            localStorage.removeItem("user");

            this.navCtrl.navigateRoot('/login');
            //this.router.navigate(['/login']);
          }
        }
      ]
    });
    await alert.present();
  }

  logoutAPICall(){

    var postData = { 
    };

    //this.allService.showLoader();
    this.allService.sendData('logout',postData).subscribe(
      data => {

        //console.log('logoutAPICall data:',data);

        //this.allService.dismissLoading();

        if(data['status']==true){

        }//true

        if(data['status']==false){
          var msgType = typeof data['message'];

          if(msgType=="string"){
            this.allService.presentAlertMsg("Info",data['message']);
          }//string

          // if(msgType=="object"){
          //   if(data['message'].online_status != undefined)
          //     this.allService.presentToast(data['message'].online_status[0]);
          // }//object

        }//false

      },
      err => {
        //this.allService.dismissLoading();
        this.allService.presentAlertMsg("Info","Something went wrong. Please Try Again");
      });

  }//logoutAPICall()


  alterToken(type){
    if(type=='a'){
      this.storage.set('access_token', 'XYZ').then(
        data => {
          this.allService.updateLatestTokens();
        });
    }

    if(type=='r'){
      this.storage.set('refresh_token', 'XYZABC').then(
        data => {
          this.allService.updateLatestTokens();
        });
    }
  }//alterToken()

  setupPushNotification(){
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
        //alert('Push Register Failed');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        this.storage.set('device_token', token.value);
        this.allService.setFCMDeviceToken(token.value);
        console.log('token.value:',token.value);
        //alert('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));

        this.processPushNotificationData(notification);
        //alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        //alert('Push action performed: ' + JSON.stringify(notification));

        
        var typeOfNotification  = typeof notification;
        var typeOfData  = typeof notification['notification']['data'];

        //alert(typeOfNotification);
        //alert(typeOfData);

        if(notification['notification']['data']['order_id']!=undefined){
          //alert('---'+notification['notification']['data']['order_id']);

          let userData = localStorage.getItem("user");
          let userJson = JSON.parse(userData);

          console.log('userData:',userData);
          console.log('userJson:',userJson);

          if(notification['notification']['data']['title']=='Drop off signature done'){
            //Driver

            let navExtra: NavigationExtras = {          
              state : {
                requestData: {id:notification['notification']['data']['order_id']},
              }
            }
            this.router.navigate(['/delivery'], navExtra);

          }
          else if(notification['notification']['data']['title']=='Order dropoff'){
            //User
            // let navExtra: NavigationExtras = {          
            //   state : {
            //     orderId: notification['notification']['data']['order_id'],
            //     signatureNotification: true,
            //   }
            // }
            //this.router.navigate(['/order'], navExtra);
            
            let navExtra: NavigationExtras;

                let is_customer_home = notification['notification']['data']['is_customer_home']
                console.log("is_customer_home:",is_customer_home);

                if(is_customer_home==1){
                console.log("is_customer_home: IF",is_customer_home);

                    navExtra = {          
                      state : {
                        orderId: notification['notification']['data']['order_id'],
                        signatureNotification: true,
                      }
                    }
                }
                else{
                  console.log("is_customer_home: ELSE",is_customer_home);
                    navExtra = {          
                      state : {
                        orderId: notification['notification']['data']['order_id'],
                      }
                    }
                }

            this.router.navigate(['/order'], navExtra);

          }
          else if(notification['notification']['data']['title']=='Order completed'){
            if(notification['notification']['data']['is_customer_home']==1){
              let navExtra: NavigationExtras = {          
                state : {
                  orderId: notification['notification']['data']['order_id'],
                }
              }
              this.router.navigate(['/order'], navExtra);
            }
            else{
              let navExtra: NavigationExtras = {          
                state : {
                  orderId : notification['notification']['data']['order_id'],
                }
              }
              this.router.navigate(['/rate-trip'], navExtra);
            }
          }
          else{
            //User

            let navExtra: NavigationExtras = {          
              state : {
                orderId: notification['notification']['data']['order_id'],
              }
            }
            this.router.navigate(['/order'], navExtra);

          }

          
          
        }
        else
        {
          //alert("No Order Id");
        }

      }
    );
  }//setupPushNotification()

  processPushNotificationData(notification){

    // var typeOfNotification  = typeof notification;
    // var typeOfData  = typeof notification['data'];

    // console.log('typeOfNotification:',typeOfNotification);
    // console.log('typeOfData:',typeOfData);

    if(notification['data']['order_id']!=undefined){
      //alert(notification['data']['order_id']);
      var title = notification['title']?notification['title']:notification['data']['title'];
      var body = notification['body']?notification['body']:notification['data']['body'];
      var is_customer_home = notification['data']['is_customer_home'];
      var order_id = notification['data']['order_id'];
      this.presentAlertPushNotificationOpenPage(title,body,order_id,is_customer_home);
  
      this.eventService.publishData({
        refreshOrderPage: true
      });

    }
    else
    {
      var title = notification['title']?notification['title']:notification['data']['title'];
      var body = notification['body']?notification['body']:notification['data']['body'];
      this.presentAlertPushNotification(title,body);
    }

  }//processPushNotificationData()

  async presentAlertPushNotification(title,body) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: title,
      message: body,
      buttons: [
        // {
        //   text: 'Cancel',
        //   role: 'cancel',
        //   cssClass: 'secondary',
        //   handler: (blah) => {
        //     console.log('Confirm Cancel: blah');
        //   }
        // }, 
        {
          text: 'Okay',
          handler: () => {
            //console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }//presentAlertPushNotification()


  async presentAlertPushNotificationOpenPage(title,body,order_id,is_customer_home) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: title,
      message: body,
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Okay Cancel');
          }
        }, 
        {
          text: 'Open',
          handler: () => {

            // let navExtra: NavigationExtras = {          
            //   state : {
            //     orderId: order_id,
            //   }
            // }
            // this.router.navigate(['/order'], navExtra);



            let userData = localStorage.getItem("user");
            let userJson = JSON.parse(userData);

              console.log('userData:',userData);
              console.log('userJson:',userJson);

              if(title=='Drop off signature done'){
                //Driver

                let navExtra: NavigationExtras = {          
                  state : {
                    requestData: {id:order_id},
                  }
                }
                this.router.navigate(['/delivery'], navExtra);

              }
              else if(title=='Order dropoff'){
                //User
                let navExtra: NavigationExtras;

                console.log("is_customer_home:",is_customer_home);

                if(is_customer_home==1){
                console.log("is_customer_home: IF",is_customer_home);

                    navExtra = {          
                      state : {
                        orderId: order_id,
                        signatureNotification: true,
                      }
                    }
                }
                else{
                  console.log("is_customer_home: ELSE",is_customer_home);
                    navExtra = {          
                      state : {
                        orderId: order_id,
                      }
                    }
                }

                this.router.navigate(['/order'], navExtra);
              }
              else if(title=='Order completed'){
                if(is_customer_home==1){
                  let navExtra: NavigationExtras = {          
                    state : {
                      orderId: order_id,
                    }
                  }
                  this.router.navigate(['/order'], navExtra);
                }
                else{
                  let navExtra: NavigationExtras = {          
                    state : {
                      orderId : order_id,
                    }
                  }
                  this.router.navigate(['/rate-trip'], navExtra);
                }
              }
              else{
                //User

                let navExtra: NavigationExtras = {          
                  state : {
                    orderId: order_id,
                  }
                }
                this.router.navigate(['/order'], navExtra);

              }


          }
        }
      ]
    });

    await alert.present();
  }//presentAlertPushNotification()

  registerBackButton() {
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/landing')) {
        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        //this._location.back();
        //this.navCtrl.back();
        this.navCtrl.pop();
      }
    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertCtrl
        .getTop()
        .then((r) => {
          if (r) {
            navigator['app'].exitApp();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
  } // registerBackButton()

  showExitConfirm() {
    this.alertCtrl
      .create({
        header: 'Exit App?',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Yes',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }//showExitConfirm()

  pwaNewVersionAvailable() {
    this.alertCtrl
      .create({
        header: 'New Version Available',
        message: 'The app will reload',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              document.location.reload();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }//pwaNewVersionAvailable()


  setupPwaPushNotification() {

    this.messagingService.requestPermission().subscribe(
      async token => {
       console.log("DivTOken", token);
       this.allService.setFCMDeviceToken(token);
      },
      async (err) => {
        console.log('PWA TOKEN GENERATE ERROR');
      }
    );
    
    this.afMessaging.onMessage((payload) => {
      alert();
      console.log('payload',payload)
    });

    this.afMessaging.onBackgroundMessage((payload) => {
      alert();
      console.log('Backgrounpayload',payload)
    });
  }

}
