import { Injectable } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { LoadingController, ToastController, AlertController } from '@ionic/angular';

import { map, timeout } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { Storage } from '@ionic/storage';

import { Router } from '@angular/router';




// let baseUrl = "https://rxod.betaplanets.com/";
// let fullUrl = "https://rxod.betaplanets.com/wp-json/mobileapi/v1/";

// let baseUrl = "https://rxondemand.tecocraft.co.in/";
// let fullUrl = "https://rxondemand.tecocraft.co.in/api/v1/";

// let baseUrl = "https://rxondemand.tecocraft.net/";
// let fullUrl = "https://rxondemand.tecocraft.net/api/v1/";

// let baseUrl = "https://rxod.betaplanets.com/";
// let fullUrl = "https://rxod.betaplanets.com/api/v1/";

let baseUrl = "https://rxondemandapp.com/back-end/";
let fullUrl = "https://rxondemandapp.com/back-end/api/v1/";



@Injectable({
	providedIn: 'root'
})
export class AllService {
  loading: any;
  public stateList = [
    "Alaska",
    "Alabama",
    "Arkansas",
    "Arizona",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Iowa",
    "Idaho",
    "Illinois",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Maine",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "Nevada",
    "New York",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Virginia",
    "Vermont",
    "Washington",
    "Wisconsin",
    "West Virginia",
    "Wyoming"
  ];

  public loginStatusSource = new Subject<any>();
  loginStatus$ = this.loginStatusSource.asObservable();

  access_token;
  refresh_token;

  public requested_user_type;
  public user_type;
  public userType;

  device_token;

  constructor(
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private router: Router,
    public alertController: AlertController,
  ) {
    this.updateLatestTokens();
   }

  getAccessToken(){
    return this.access_token;
  }

  getURL() {
    return baseUrl;
  }

  getFullURL() {
    return fullUrl;
  }

  getData(endPoint) {
    return this.http.get(fullUrl + endPoint).pipe(
      map(data => {
        return data;
      })
    )
  }

  sendData(endPoint, data) {

    //console.log('access_token:',{'access_token':this.access_token});
    
    return this.http.post(fullUrl + endPoint, data).pipe(
      //timeout(1000),
      map(data => {
        return data;
      })
    );
  }

  sendDataAuth(endPoint, data) {

    //console.log('access_token:',{'access_token':this.access_token});

    const httpOptions = {
      headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json', //'application/x-www-form-urlencoded'
      })
    };

    return this.http.post(fullUrl + endPoint, data, httpOptions).pipe(
      map(data => {
          return data;
      })
    )
  }

  updateAlertStatus(endPoint) {

    //console.log('access_token:',{'access_token':this.access_token});

    const httpOptions = {
      headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json', //'application/x-www-form-urlencoded'
      })
    };

    return this.http.post(fullUrl + endPoint, {terms_check: 1}, httpOptions).pipe(
      map(data => {
          return data;
      })
    )
  }

  doRefreshToken() {

    var endPoint = 'refreshtoken';

    const httpOptions = {
      headers: new HttpHeaders({
          'refresh_token': this.refresh_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      })
    };

    return this.http.post(fullUrl + endPoint, {}, httpOptions).pipe(
      map(data => {

        if(data['status']==true){

          var access_token = data['data']['access_token'];
          var refresh_token = data['data']['refresh_token'];

          this.access_token = access_token;
          this.refresh_token = refresh_token;

          this.storage.set('access_token', access_token);
          this.storage.set('refresh_token', refresh_token);

        }//true

        if(data['status']==false){
          var msgType = typeof data['message'];

          if(msgType=="string"){
            this.presentAlertMsg("Info",data['message']);
          }//string

        }//data false

        return data;
      }) );
  }//doRefreshToken


  doLogin(loginData) {
    return this.http.post(baseUrl+'wp-json/jwt-auth/v1/token', loginData).pipe(
      map(data => {
        //this.loginStatusSource.next('login');
        return data;
      })
    )
  }

  doLogout() {
    this.loginStatusSource.next({event:'logout'});
  }

  async showLoader(){
    this.loading = await this.loadingCtrl.create({
      message: 'please wait',
      backdropDismiss: true,
    });
    
    this.loading.present();
    await this.loading.onDidDismiss();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      position:'middle'
    });
  
    toast.present();
  }

  updateLatestTokens(){
    //console.log("fetchLatestTokens : get");

    this.storage.get('access_token').then((val) => {
      //console.log('allService access_token get:',val);
      if(val!=null){
        this.access_token = val;
      }
    });

    this.storage.get('refresh_token').then((val) => {
      //console.log('allService refresh_token get:',val);
      if(val!=null){
        this.refresh_token = val;
      }
    });


  }//updateLatestTokens()

  setUserType(a,b){
    //console.log('setUserType requested_user_type:',a);
    //console.log('setUserType user_type:',b);

    this.requested_user_type = a;
    this.user_type = b;

    if(this.user_type==1 || this.user_type==""){
      this.userType = "User";
    }

    if(this.user_type==3){
      this.userType = "Driver"; 
    }
    console.log('setUserType userType:',this.userType);
  }//setUserType()


  // handleError(err){
  //   if (err instanceof HttpErrorResponse) {
  //       if (err.error instanceof ErrorEvent) {
  //           console.error("Error Event");
  //       } else {
  //           console.log(`error status : ${err.status} ${err.statusText}`);
  //           switch (err.status) {
  //               case 401:      //login
  //                   //this.router.navigateByUrl("/login");
  //                   break;
  //               case 403:     //forbidden
  //                   //this.router.navigateByUrl("/unauthorized");
  //                   break;
  //           }
  //       } 
  //   } else {
  //       console.error("some thing else happened");
  //   }
  // }//handleError()

  setFCMDeviceToken(token){
    this.device_token = token;
  }//setFCMDeviceToken()

  getFCMDeviceToken(){
    return this.device_token;
  }//getFCMDeviceToken()

  async presentAlertMsg(title,msg) {
    const alert = await this.alertController.create({
      cssClass: 'alert-custom-class',
      header: title,
      mode: 'ios',
      //subHeader: 'Subtitle',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();

    setTimeout(() => {
      alert.dismiss();
    }, 4500);
  }//presentAlertMsg

  getUser(){
    return localStorage.getItem("user");
  }

  getPlaceDetailsGoogle(placeid) {
    let googleUrl = 'https://maps.googleapis.com/maps/api/place/details/json?placeid='+placeid+'&key=AIzaSyCgMtqjlkH4WyvbDI0ixK6KJmx6R3Z8Gm4';
    return this.http.get(googleUrl).pipe(
      map(data => {
        return data;
      })
    )
  }//getPlaceDetailsGoogle()

  

}