import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private afMessaging: AngularFireMessaging) { }

  requestPermission() {
    return this.afMessaging.requestToken.pipe(
      tap(token => {
        console.log('Store token to server: ', token);
      })
    );
  }
 
  getMessages() {
    return this.afMessaging.messages;
  }

  onMessages() {
    this.afMessaging.onMessage((payload) => {
      console.log('payload',payload)
    });
  }


}
