import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AllService } from '../all.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService {

  constructor(
    private router: Router,
    private allService: AllService,
    private storage: Storage,
  ){}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const user = JSON.parse(this.allService.getUser());

    if (user) {
      this.router.navigate(["/landing"]);
      // this.router.navigate(["/addnote"]);
      return false;
    
    } else {
      return true;
    }

  }//canActive()

}
